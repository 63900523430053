import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../components/layouts"
import { buildBreadcrumb } from "./../utils/buildBreadcrumb"
import Breadcrumb from "./../components/slices/Breadcrumb"
import { Hero } from "./../components/hero"
import {
  Text,
  AbstractImageBlock1,
  AbstractImageBlock2,
} from "./../components/slices"

// Query for the Blog Post content in Prismic
export const query = graphql`
  query StandardContentQuery($uid: String!) {
    allPrismicHomepage {
      edges {
        node {
          data {
            seotitle
            seodescription
            title {
              text
            }
          }
          id
          type
        }
      }
    }
    prismicStandardContentPage(uid: { eq: $uid }) {
      data {
        seotitle
        seodescription
        title {
          html
          text
          raw
        }
        hero_text_colour
        hero_image {
          alt
          url
        }
        body {
          __typename
          ... on PrismicStandardContentPageBodyText {
            slice_type
            slice_label
            primary {
              content {
                html
                text
                raw
              }
            }
          }
          ... on PrismicStandardContentPageBodyAbstractImageBlock1 {
            slice_type
            slice_label
            primary {
              image_1 {
                url
              }
              image_2 {
                url
              }
            }
          }
          ... on PrismicStandardContentPageBodyAbstractImageBlock2 {
            slice_type
            slice_label
            primary {
              image_1 {
                url
              }
              image_2 {
                url
              }
            }
          }
        }
      }
      id
      uid
      type
    }
  }
`

// Sort and display the different slice options
const StandardContentSlices = ({ slices }) => {
  return slices.map((slice, index) => {
    const res = (() => {
      switch (slice.slice_type) {
        case "text":
          return (
            <div key={index} className="homepage-slice-wrapper">
              {<Text slice={slice} />}
            </div>
          )

        case "abstract_image_block_1":
          return (
            <div key={index} className="homepage-slice-wrapper">
              {<AbstractImageBlock1 slice={slice} />}
            </div>
          )

        case "abstract_image_block_2":
          return (
            <div key={index} className="homepage-slice-wrapper">
              {<AbstractImageBlock2 slice={slice} />}
            </div>
          )

        default:
          return <h1>Missing slice - {slice.slice_type}</h1>
      }
    })()
    return res
  })
}

export default ({ data }) => {
  // Define the Post content returned from Prismic
  const { prismicStandardContentPage: pageData, allPrismicHomepage } = data
  const homeData = allPrismicHomepage.edges[0].node
  const thisBreadcrumb = buildBreadcrumb([homeData, pageData])

  return (
    <Layout>
      <Helmet>
        <title>
          {pageData.data.seotitle
            ? pageData.data.seotitle
            : homeData.data.seotitle}
        </title>
        <meta
          name="description"
          content={
            pageData.data.seodescription
              ? pageData.data.seodescription
              : homeData.data.seodescription
          }
        />
      </Helmet>
      <Hero className="compact" props={pageData.data} />
      <div className="container -pinched">
        <Breadcrumb className="border-bottom" data={thisBreadcrumb} />
      </div>
      <div className="standard-content">
        <div className="[ container -pinched-large ]">
          <div className="standard-content__content">
            <StandardContentSlices slices={pageData.data.body} />
          </div>
        </div>
      </div>
    </Layout>
  )
}
