import React from "react"
import image from "./../../images/shop-my-instagram-d.jpg"
import { Link } from "gatsby"
import linkResolver from "../../utils/linkResolver"

export default ({ slice }) => {
  const { primary } = slice
  const {
    shop_my_instagram_banner_title,
    snippet,
    shop_my_instagram_banner_link,
  } = primary

  if (shop_my_instagram_banner_link.url) {
    shop_my_instagram_banner_link.type = "link"
  }

  return (
    <div className="shop-my-instagram-banner">
      <div className="[ container -pinched ]">
        <div className="shop-my-instagram-banner__grid">
          <div className="shop-my-instagram-banner__content">
            <h3 className="title h3">{shop_my_instagram_banner_title.text}</h3>
            <p>{snippet.text}</p>
            <div className="shop-my-instagram__button [ button-holder -centered ]">
              <Link
                className="[ button ]"
                to={linkResolver(shop_my_instagram_banner_link)}
                target={
                  shop_my_instagram_banner_link.type === "link"
                    ? "_blank"
                    : "_self"
                }
              >
                {shop_my_instagram_banner_title.text}
              </Link>
            </div>
          </div>
          <div className="shop-my-instagram-banner__media">
            <img
              className="shop-my-instagram-banner__image"
              src={image}
              loading="lazy"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  )
}
