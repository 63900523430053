import React from "react"

export default ({ slice }) => {
  const { image_1, image_2 } = slice.primary

  return (
    <div className="abstract-image-block abstract-image-block--block1">
      <img
        className="abstract-image-block__image1"
        src={image_1.url}
        loading="lazy"
        alt=""
      />
      <img
        className="abstract-image-block__image2"
        src={image_2.url}
        loading="lazy"
        alt=""
      />
      <div className="abstract-image-block__shape" />
    </div>
  )
}
