import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { EditPod } from "./../pods"

export default ({ slice }) => {
  const data = useStaticQuery(graphql`
    query LatestEditsBannerQuery {
      allPrismicEdit(
        limit: 5
        sort: { fields: data___published_date, order: DESC }
      ) {
        edges {
          node {
            data {
              title {
                text
              }
              hero_image {
                url(imgixParams: { q: 70, w: 645, h: 246 })
              }
              snippet {
                text
              }
              published_date
              category {
                __typename
                document {
                  ... on PrismicCategory {
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
            uid
            id
            type
          }
        }
      }
    }
  `)

  const allEdits = data.allPrismicEdit.edges

  return (
    <div className="latest-edits">
      <div className="[ container -pinched ]">
        <div className="latest-edits__content">
          <h2 className="title">
            {slice.primary.latest_edits_banner_title.text}
          </h2>
          {slice.primary.latest_edits_banner_content && (
            <p>{slice.primary.latest_edits_banner_content.text}</p>
          )}
        </div>
        <div className="latest-edits__grid">
          {allEdits.map((edit, index) => {
            let editItem = edit.node
            return <EditPod data={editItem} key={index} />
          })}
        </div>
        <div className="button-holder -centered">
          <Link className="[ button ]" to="/edits">
            <span>View all edits</span>
          </Link>
        </div>
      </div>
    </div>
  )
}
