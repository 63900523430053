import React from "react"
import { RichText } from "prismic-reactjs"

export default ({ slice }) => {
  const {
    title1: title,
    product_name,
    content,
    image,
    price,
    merchant,
    link,
    button_text,
  } = slice.primary

  return (
    <div className="favourite-item">
      <h2 className="title">{title.text}</h2>
      {content && (
        <div className="favourite-item__content">
          {RichText.render(content)}
        </div>
      )}
      <div className="favourite-item__image-holder">
        <a href={link.url} target="_blank" rel="nofollow noreferrer">
          <img
            src={image.url}
            alt=""
            className="favourite-item__image"
            loading="lazy"
          />
        </a>
      </div>
      <div className="favourite-item__subcontent">
        {product_name.text && (
          <p className="favourite__product-name">
            <a
              href={link.url}
              className="link--multiline"
              target="_blank"
              rel="nofollow noreferrer"
            >
              <span>{product_name.text}</span>
            </a>
          </p>
        )}
        {price && <p className="favourite__price">{price}</p>}
        {merchant && <p className="favourite__retailer">{merchant}</p>}
        <p className="favourite-item__link">
          <a
            className="[ button ]"
            href={link.url}
            target="_blank"
            rel="noreferrer nofollow"
          >
            {button_text ? button_text : "Shop now"}
          </a>
        </p>
      </div>
    </div>
  )
}
