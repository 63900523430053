import React from "react"
import linkResolver from "./../../utils/linkResolver"
import { Link } from "gatsby"
import { IconLoveStory } from "./../svg"

export default ({ slice }) => {
  const {
    about_me_banner_title,
    about_me_banner_content,
    about_me_banner_link,
    image,
  } = slice.primary

  return (
    <div className="about-me">
      <div className="[ container -pinched ]">
        <div className="about-me__inner">
          <div className="about-me__media">
            <img src={image.url} alt="" loading="lazy" />
          </div>
          <div className="about-me__content">
            <div className="about-me__content-inner">
              <h3 className="title h3">{about_me_banner_title.text}</h3>
              <p>{about_me_banner_content.text}</p>
              <Link
                className="button"
                to={linkResolver(about_me_banner_link.document)}
              >
                Read my story
              </Link>
            </div>
          </div>
          <div className="about-me__content-background"></div>
          <div className="about-me__signature">
            <IconLoveStory className="icon icon--about-me" />
          </div>
        </div>
      </div>
    </div>
  )
}
