import React from "react"
import { RichText } from "prismic-reactjs"

export default ({ slice }) => {
  let text

  if (slice.primary.text) {
    text = slice.primary.text.raw
  }

  if (slice.primary.content) {
    text = slice.primary.content.raw
  }

  return <div className="rich-text">{RichText.render(text)}</div>
}
