import React from "react"
import linkResolver from "./../../utils/linkResolver"
import { Link } from "gatsby"

export default ({ slice }) => {
  const { items, primary } = slice
  const {
    navigation_pod_title,
    navigation_banner_image1,
    navigation_banner_image_2,
    navigation_banner_image_3,
  } = primary

  return (
    <div className="navigation-banner">
      <div className="container">
        <div className="navigation-banner__inner">
          <div className="navigation-banner__content">
            <p className="navigation-banner__title">
              {navigation_pod_title.text}
            </p>
            <ul className="navigation-banner__links">
              {items &&
                items.map((item, index) => {
                  return (
                    <li key={index}>
                      <Link
                        className="navigation-banner__link link"
                        to={linkResolver(item.navigation_banner_link)}
                      >
                        <span>
                          {item.navigation_banner_link.document.data.title.text}
                        </span>
                      </Link>
                    </li>
                  )
                })}
            </ul>
          </div>
          {navigation_banner_image1 && (
            <img
              className="navigation-banner__image1"
              src={navigation_banner_image1.url}
              loading="lazy"
              alt=""
            />
          )}
          {navigation_banner_image_2 && (
            <img
              className="navigation-banner__image2"
              src={navigation_banner_image_2.url}
              loading="lazy"
              alt=""
            />
          )}
          {navigation_banner_image_3 && (
            <img
              className="navigation-banner__image3"
              src={navigation_banner_image_3.url}
              loading="lazy"
              alt=""
            />
          )}
        </div>
      </div>
    </div>
  )
}
