import React from "react"
import { RichText } from "prismic-reactjs"

export default ({ slice }) => {
  const { title, content, image, price, retailer, link } = slice.primary.item

  return (
    <div className="favourite-item">
      <h2 className="title">{RichText.asText(title)}</h2>
      {content && (
        <div className="favourite-item__content">
          {RichText.render(content)}
        </div>
      )}
      <div className="favourite-item__image">
        <img src={image.url} alt={RichText.asText(title)} loading="lazy" />
      </div>
      <div className="favourite-item__subcontent">
        <p className="favourite__price">{price}</p>
        <p className="favourite__retailer">{retailer}</p>
        <p className="favourite-item__link">
          <a
            className="[ button ]"
            href={link.url}
            target="_blank"
            rel="noreferrer nofollow"
          >
            Shop now
          </a>
        </p>
      </div>
    </div>
  )
}
