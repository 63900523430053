import React from "react"

export default () => {
  return (
    <div className="google-ad">
      <div className="container">
        <img
          src="https://i.picsum.photos/id/10/728/90.jpg?hmac=uIG4A9xM894pnJm-tdhCL_pMD9Jv9WikHT6gN7b-hp8"
          alt=""
          loading="lazy"
        />
      </div>
    </div>
  )
}
