import React from "react"
import linkResolver from "../../utils/linkResolver"
import { StaticQuery, Link, graphql } from "gatsby"
import { FavouritePod } from "./../pods"

export default (props) => (
  <StaticQuery
    query={graphql`
      query LatestFavsBannerQuery {
        allPrismicFavourit(
          limit: 8
          sort: { fields: first_publication_date, order: DESC }
        ) {
          edges {
            node {
              data {
                title {
                  text
                }
                image {
                  url
                }
                link {
                  __typename
                  url
                }
              }
              id
              first_publication_date
              type
            }
          }
        }
      }
    `}
    render={(data) => <LatestFavsBanner data={data} {...props} />}
  />
)

const LatestFavsBanner = (props) => {
  const slice = props.slice
  const latestFavs = props.data.allPrismicFavourit.edges

  if (!latestFavs.length) {
    return null
  }

  return (
    <div className="latest-favs">
      <div className="[ container -pinched ]">
        <div className="latest-favs__content">
          <h2 className="[ title title--line ] homepage--title">
            <span>{slice.primary.latest_favs_banner_title.text}</span>
          </h2>
          <p className="latest-favs__description">
            {slice.primary.latest_favs_banner_content.text}
          </p>
        </div>
        <div className="latest-favs__grid">
          {latestFavs.map((latestFav, index) => {
            return <FavouritePod key={index} data={latestFav} />
          })}
        </div>
        <div className="[ button-holder -centered ]">
          <Link
            className="[ button ]"
            to={linkResolver(slice.primary.latest_favs_banner_link)}
          >
            Shop latest favs
          </Link>
        </div>
      </div>
    </div>
  )
}
